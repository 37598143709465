import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import * as sections from "../components/sections"
import Fallback from "../components/fallback"

export default function Page(props) {
  const { subPage } = props.data
  const description = subPage?.description?.description
  delete subPage.description

  let cardSectionCount = 0
  return (
    <Layout description={description} {...subPage}>
      {subPage.blocks &&
        subPage.blocks.map((block, index) => {
          const { id, blocktype, ...componentProps } = block
          const Component = sections[blocktype] || Fallback
          if (
            blocktype === "ImageCardSection" ||
            blocktype === "VideoCardSection"
          ) {
            cardSectionCount++
          }
          return (
            <Component
              key={id}
              index={index}
              cardSectionCount={cardSectionCount}
              {...componentProps}
            />
          )
        })}
    </Layout>
  )
}

export const query = graphql`
  query SubPageContent($id: String!) {
    subPage(id: { eq: $id }) {
      id
      slug
      title
      ... on ContentfulSubPage {
        description {
          description
        }
      }
      image {
        id
        url
      }
      blocks: content {
        id
        blocktype
        ...HomepageHeroContent
        ...HomepageBenefitListContent
        ...ImageCardSectionContent
        ...PortraitQuoteContent
        ...VideoCardSectionContent
        ...ProductListContent
        ...FeedbackPreviewListSectionContent
        ...ArrowStepsContent
        ...RichTextSectionContent
        ...BigCardListSectionContent
        ...VideoSectionContent
        ...NumberedListSectionContent
        ...CtaCardSectionContent
        ...VideoFeedbackSectionContent
      }
    }
  }
`
